<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="4">
        <v-card class="mx-auto goldColor" max-width="400" shaped>
          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-title class="text-h6">
                Main Balance
              </v-list-item-title>
              <v-list-item-subtitle class="white--text text-h6">
                {{ DisplayCurrency(getUser.mainBalance) }}
              </v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-avatar tile size="45" color="black">
              <v-icon color="#d4b506">mdi-currency-usd</v-icon>
            </v-list-item-avatar>
          </v-list-item>
        </v-card>
      </v-col>
      <v-col cols="12" md="4">
        <v-card class="mx-auto goldColor" max-width="400" shaped>
          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-title class="text-h6"> Profit </v-list-item-title>
              <v-list-item-subtitle class="white--text text-h6">
                {{ DisplayCurrency(getUser.totalProfit) }}
              </v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-avatar tile size="45" color="black">
              <v-icon color="#d4b506">mdi-credit-card-check-outline</v-icon>
            </v-list-item-avatar>
          </v-list-item>
        </v-card>
      </v-col>
      <v-col cols="12" md="4">
        <v-card class="mx-auto goldColor" max-width="400" shaped>
          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-title class="text-h6">
                Total Deposit
              </v-list-item-title>
              <v-list-item-subtitle class="white--text text-h6">
                {{ getUser.totalDeposit }}
              </v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-avatar tile size="45" color="black">
              <v-icon color="#d4b506">mdi-account-credit-card-outline</v-icon>
            </v-list-item-avatar>
          </v-list-item>
        </v-card>
      </v-col>
      <v-col cols="12" md="4">
        <v-card class="mx-auto goldColor" max-width="400" shaped>
          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-title class="text-h6">
                Total Withdrawal
              </v-list-item-title>
              <v-list-item-subtitle class="white--text text-h6">
                {{ getUser.totalWithdrawal }}
              </v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-avatar tile size="45" color="black">
              <v-icon color="#d4b506">mdi-credit-card-outline</v-icon>
            </v-list-item-avatar>
          </v-list-item>
        </v-card>
      </v-col>
      <v-col cols="12" md="4">
        <v-card class="mx-auto goldColor" max-width="400" shaped>
          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-title class="text-h6">
                Referral Bonus
              </v-list-item-title>
              <v-list-item-subtitle class="white--text text-h6">
                {{ DisplayCurrency(getUser.referralBonus) }}
              </v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-avatar tile size="45" color="black">
              <v-icon color="#d4b506">mdi-currency-usd</v-icon>
            </v-list-item-avatar>
          </v-list-item>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import currencyFormatter from "currency-formatter";

export default {
  computed: {
    getUser() {
      return this.$store.getters.getUser;
    },
  },
  methods: {
    DisplayCurrency(payload) {
      return currencyFormatter.format(payload, {
        code: "USD",
      });
    },
  },
};
</script>

<style scoped>
.goldColor {
  background-color: #d4b506 !important;
}
</style>