import { render, staticRenderFns } from "./InvestmentHistory.vue?vue&type=template&id=fc91fdde&"
import script from "./InvestmentHistory.vue?vue&type=script&lang=js&"
export * from "./InvestmentHistory.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports