<template>
  <v-container>
    <v-data-table
      dark
      :headers="headers"
      :items="getInvestmentHistory"
      class="elevation-1"
      :expanded.sync="expanded"
      item-key="_id"
      :loading="TableLoading"
      loading-text="Loading... Please wait"
      :items-per-page="20"
      hide-default-footer
    >
      <template v-slot:item.amount="{ item }">
        {{ DisplayCurrency(item.amount) }}
      </template>
      <template v-slot:item.totalEarnings="{ item }">
        {{ DisplayCurrency(item.totalEarnings) }}
      </template>
    </v-data-table>
  </v-container>
</template>
<script>
import currencyFormatter from "currency-formatter";

export default {
  data: () => ({
    headers: [
      {
        text: "Investment Type",
        align: "start",
        sortable: false,
        value: "investmentName",
      },
      { text: "Currency", value: "coin", sortable: false },
      { text: "Amount Invest", value: "amount", sortable: false },
      { text: "DailyReturnRate", value: "dailyReturnRate", sortable: false },
      { text: "TotalReturnRate", value: "totalReturnRate", sortable: false },
      { text: "TotalEarnings", value: "totalEarnings", sortable: false },
      { text: "Status", value: "status", sortable: false },
    ],
  }),
  computed: {
    getInvestmentHistory() {
      return this.$store.getters.getInvestmentHistory;
    },
    getToken() {
      return this.$store.getters.getToken;
    },
  },
  methods: {
    DisplayCurrency(payload) {
      return currencyFormatter.format(payload, {
        code: "USD",
      });
    },
    GetInvestmentHistory() {
      let payload = {
        token: this.getToken,
      };
      this.$store.dispatch("GetInvestment", payload);
    },
  },
  created() {
    this.GetInvestmentHistory();
  },
};
</script>